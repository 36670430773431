import {useEffect, useState} from "react";
import Preloader from "../../../../../preloader/preloader";
import {useDispatch} from "react-redux";
import {currentApplicationServiceById} from "../../../../../../store/applications/application-service-order/applicationServiceOrderSlice";
// конкретный заказ со списком услуг у менеджера
function ApplicationCurrentService(
    {
        service,
        handleDeleteService,
        setApprovedPrice,
        currentApplicationId,
        orderID,
        setShowApproveBtn,
    }) {
    const dispatch = useDispatch()
    const statusMap = {
        toApprove: { name: 'Необходимо согласовать', color: '#F2994A', id: 1 },
        toFill: { name: 'Необходимо заполнить', color: '#EB5757', id: 2 },
        ready: { name: 'Готово к работе', color: '#27AE60', id: 3 },
        deleted: { name: 'Удалена', color: '#EB5757', id: 0 }
    }
    const [pricesValue, setPricesValue] = useState({
        price_for_us: '0',
        approved_price: '0',
        parking_has_service_id: 0
    })
    const [currentStatus, setCurrentStatus] = useState(statusMap.toApprove)
    const [changedPartnerPrise, setChangedPartnerPrise] = useState(false)


    useEffect(() => {
        setPricesValue({
            price_for_us: service.price_for_us,
            approved_price: service.approved_price,
            parking_has_service_id: service.parking_has_service?.id,
        })
    }, [service])
    useEffect(()=>{
        dispatch(currentApplicationServiceById({currentApplicationId, orderID})).then((response)=>{
        })
        statusHandler()
    },[service])
    const statusHandler = () =>{
        let updatedStatus = 0;
        switch(service.additional_service){
            case false :
                if(isNaN(parseInt(service.price_for_us))  || isNaN(parseInt(service.approved_price))){
                    updatedStatus = statusMap.toFill
                }
                if (service.status_id === 9) {
                    updatedStatus = statusMap.toApprove
                }
                else{
                    updatedStatus = statusMap.ready
                }
                break
            case true :
                if (service.status_id === 3) {
                    updatedStatus = statusMap.ready
                } else {
                    updatedStatus = statusMap.toApprove
                }
        }
        setCurrentStatus(updatedStatus)
        setShowApproveBtn(updatedStatus.id)
    }
    const handleChange = (e) => {
        const {name, value} = e.target ? e.target : e;
        setPricesValue({...pricesValue, [name]: value});
    }
    const changeDefaultPrice = (e, id) => {
        handleChange(e)
        if (e.target.name === 'approved_price') {
            setApprovedPrice(prevState => {
                return {
                    orderServices: prevState.orderServices.map(service => {
                        if (service.id === id) {
                            return {
                                ...service,
                                price: e.target.value,
                            };
                        }
                        return service;
                    })
                };
            });
            if(isNaN(parseInt(e.target.value))){
                setCurrentStatus( statusMap.toFill)
                setShowApproveBtn(statusMap.toFill.id)
            }
            else{
                if (parseInt(e.target.value) !== parseInt(service.approved_price && service.status_id !== 9)){
                    setCurrentStatus( statusMap.toApprove)
                    setShowApproveBtn(statusMap.toApprove.id)
                    setChangedPartnerPrise(true)

                }else{
                    setCurrentStatus( statusMap.ready)
                    setShowApproveBtn(statusMap.ready.id)
                    setChangedPartnerPrise(false)

                }
            }

            // updateServiceStatus(service.id, parseInt(e.target.value) === 0 || parseInt(pricesValue.price_for_us) === 0  ? statusMap.toFill.id : statusMap.toApprove.id);

        }
        if (e.target.name === 'price_for_us') {
            setApprovedPrice(prevState => {
                return {
                    orderServices: prevState.orderServices.map(service => {
                        if (service.id === id) {
                            return {
                                ...service,
                                price_for_us: e.target.value
                            };
                        }
                        return service;
                    })
                };
            });

            if(isNaN(parseInt(e.target.value))) {
                setCurrentStatus( statusMap.toFill)
                setShowApproveBtn(statusMap.toFill.id)
            }
            else {
                if (changedPartnerPrise){
                    setCurrentStatus(statusMap.toApprove)
                    setShowApproveBtn(statusMap.toApprove.id)
                }else{
                    setCurrentStatus(statusMap.ready)
                    setShowApproveBtn(statusMap.ready.id)
                }
            }
        }
    }

    return (
        <>
            {service ?
                <tr>
                    <td
                        onClick={()=>{handleDeleteService(service.id)}}
                        className={'delete_service'}
                    >
                        {service.status !== 'Удалена' &&
                        <span className="">
                            <svg width="10" height="11" viewBox="0 0 10 11" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.52827 1.02729C0.788619 0.766944 1.21073 0.766944 1.47108 1.02729L4.99967 4.55589L8.52827 1.02729C8.78862 0.766944 9.21073 0.766944 9.47108 1.02729C9.73143 1.28764 9.73143 1.70975 9.47108 1.9701L5.94248 5.4987L9.47108 9.02729C9.73143 9.28764 9.73143 9.70975 9.47108 9.9701C9.21073 10.2305 8.78862 10.2305 8.52827 9.9701L4.99967 6.44151L1.47108 9.9701C1.21073 10.2305 0.788619 10.2305 0.52827 9.9701C0.26792 9.70975 0.26792 9.28764 0.52827 9.02729L4.05687 5.4987L0.52827 1.9701C0.26792 1.70975 0.26792 1.28764 0.52827 1.02729Z"
                                    fill="#EB5757"/>
                            </svg>
                        </span>
                        }
                    </td>
                    <td
                        style={service.status === 'Удалена' ? {color: 'red', maxWidth: '200px'} : {color: '#000', maxWidth: '200px'}}
                    >
                        <div className="table-service-title">{service.parking_has_service !== null ? service.parking_has_service?.parking_service?.name : service.custom_service}</div>
                    </td>

                    <td className="text-center"
                        style={{maxWidth: '170px'}}
                    >
                        {service.status_id !== 3  ?
                            <input
                                id="text"
                                type="text"
                                className="form-control "
                                name="approved_price"
                                autoComplete="text"
                                onChange={(e) => {
                                    changeDefaultPrice(e, service.id)
                                }}
                                value={pricesValue.approved_price}
                            /> :
                            <span
                                className=""
                                style={{fontSize: '1rem'}}
                            >
                                {service.status !== 'Удалена' && pricesValue.approved_price}
                            </span>
                        }
                    </td>
                    <td className="text-center" style={{maxWidth: '170px'}} >
                        {service.status_id !== 3 ?
                            <input
                                id="text"
                                type="text"
                                className="form-control "
                                name="price_for_us"
                                autoComplete="text"
                                onChange={(e) => {
                                    changeDefaultPrice(e, service.id)
                                }}
                                value={pricesValue.price_for_us === null ? 0 : pricesValue.price_for_us}
                            /> :
                            <span
                                className=""
                                style={{fontSize: '1rem'}}
                            >
                                {service.status !== 'Удалена' && pricesValue.price_for_us}
                            </span>
                        }
                    </td>
                    <td className="text-right">
                        <label className="switch-radio-wrap d-flex flex-row-reverse">
                            <span
                                style={service.status === 'Удалена' ? {color: 'red'} : {color: currentStatus.color}}
                                className="condition"
                            >
                                {
                                    currentStatus.id === 3 ? currentStatus.name : service.status

                                    // service.status === 'Удалена' ||  service.status === 'Согласована' ? service.status  :  currentStatus.name
                                }
                            </span>
                        </label>
                    </td>
                </tr>
                : <Preloader/>
            }
        </>
    );
}

export default ApplicationCurrentService;