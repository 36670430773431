import React from 'react';
import {bindReporter} from "web-vitals/dist/modules/lib/bindReporter";

function ApplicationFooterService(
    {
        item,
        role,
        sendApplicationSelectedServices,
        servicesForFooter,
        k,
        closeAddService,
        cancelServiceById,
        services,
        approveServiceByManager,
        approveServiceByPartner,
        openServicesModal,
        sendOrderToWork,
        sendOrderToWorkFromCreatingOrder,
        openAddService,
        showAddServices,
        handleAddServiceToOrder,
        showApproveBtn,

    }) {

    const formatDate = (inputDate) => {
        const dateObject = new Date(inputDate);
        const day = dateObject.getDate().toString().padStart(2, '0');
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObject.getFullYear().toString().slice(2, 4);

        return `${day}.${month}.${year}`;
    };
    switch (k) {
        case 'tabServices' :
            return (
                <>
                    <div className="tab-content tab-content-bottom " style={{width: '100%'}}>
                        <div id="v-pills-tab10-bottom" className="tab-pane fade show in active">
                            <div className="modal-block__footer d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="total-service">Заказ
                                        на <span>{servicesForFooter.length}</span> услуг
                                    </div>
                                    <p className="text11pxnormalblue d-block total-service-name">
                                        {servicesForFooter.map((item, index) => item.name + " ")}
                                    </p>

                                </div>
                                <div className="d-flex">
                                    <a href="#" onClick={() => {
                                        sendApplicationSelectedServices()
                                    }}
                                       className={`btn btn-success ${servicesForFooter.length === 0 ? 'disabled' : ''}`}

                                    >Сделать заказ</a>
                                    {role !== 'Partner' &&
                                    <a href="#"
                                       style={{background: '#536E9B', color: '#fff'}}
                                       onClick={() => {
                                           sendOrderToWorkFromCreatingOrder()
                                       }}
                                       className={`btn  ${servicesForFooter.length === 0 ? 'disabled' : ''}`}
                                    >
                                        В работу
                                    </a>}
                                </div>
                            </div>

                        </div>
                    </div>
                </>
            )
        case 'tabCurrentServices' :
            if (role !== 'Partner') {
                if (showAddServices) {
                    return <div className="tab-content tab-content-bottom" style={{width: '100%'}}>
                        <div id="v-pills-tab10-bottom" className="tab-pane fade show in active">
                            <div className="modal-block__footer d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="total-service">Заказ
                                        на <span>{servicesForFooter.length}</span> услуг
                                    </div>
                                    <p className="text11pxnormalblue d-block total-service-name">
                                        {servicesForFooter.map((item, index) => item.name + " ")}
                                    </p>
                                </div>
                                <div className="d-flex justify-content-center align-items-center gap-2">
                                    {services.currentService.order?.status_code !== 'canceled' || services.currentService.order?.status_code === 'toManager' ?
                                        <div style={
                                            {
                                                display: 'flex',
                                                gap: '10px',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                            {services.currentService.order?.status_code !== 'cancelled' ?
                                                <a href="#"
                                                   style={{maxWidth: '120px'}}
                                                   onClick={() => {
                                                       closeAddService()
                                                   }}
                                                   className="btn-text btn-outline-danger text-center mr-3">
                                                    Отменить
                                                </a> : ''
                                            }
                                            <div className="service_buttons_col">
                                                <a href="#"
                                                   onClick={() => {
                                                       handleAddServiceToOrder()
                                                   }}
                                                   className={`btn btn-success`}
                                                   style={{minWidth: '200px'}}
                                                >
                                                    Добавить услуги
                                                </a>
                                            </div>


                                        </div> : ''
                                    }

                                </div>
                            </div>

                        </div>
                    </div>
                } else {
                    return (
                        <div className="tab-content tab-content-bottom " style={{width: '100%'}}>
                            <div id="v-pills-tab10-bottom" className="tab-pane fade show in active">
                                <div className="modal-block__footer d-flex justify-content-between align-items-center">
                                    <div>
                                        <div className="total-service">Заказ
                                            № <span>{services.currentService.order?.id}</span> от {formatDate(services.currentService.order?.created_at)}
                                        </div>
                                        <p className="text11pxnormalblue d-block total-service-name">
                                            {services.currentService.order?.status_name}
                                        </p>

                                    </div>
                                    <div className="d-flex justify-content-center align-items-center gap-2">
                                        {services.currentService.order?.status_code !== 'cancelled' ?
                                            <a href="#"
                                               style={{maxWidth: '120px'}}
                                               onClick={() => {
                                                   cancelServiceById()
                                               }}
                                               className="btn-text btn-outline-danger text-center mr-3">
                                                Отменить заказ
                                            </a> : ''
                                        }
                                        {services.currentService.order?.status_code === 'toManager' || services.currentService.order?.status_code === 'approved' ?
                                            <div style={
                                                {
                                                    display: 'flex',
                                                    gap: '10px',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                {services.currentService.order?.status_code !== 'approved' &&
                                                    <button
                                                        onClick={() => {
                                                            openAddService()
                                                        }}
                                                        className={' btn-add__service'}
                                                    >
                                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M12.5 4.5C13.0523 4.5 13.5 4.94772 13.5 5.5V11.5H19.5C20.0523 11.5 20.5 11.9477 20.5 12.5C20.5 13.0523 20.0523 13.5 19.5 13.5H13.5V19.5C13.5 20.0523 13.0523 20.5 12.5 20.5C11.9477 20.5 11.5 20.0523 11.5 19.5V13.5H5.5C4.94772 13.5 4.5 13.0523 4.5 12.5C4.5 11.9477 4.94772 11.5 5.5 11.5H11.5V5.5C11.5 4.94772 11.9477 4.5 12.5 4.5Z"
                                                                fill="white"/>
                                                        </svg>
                                                        Добавить услугу
                                                    </button>
                                                }

                                                <div className="service_buttons_col">

                                                    {services.currentService.order?.status_code !== 'approved' && showApproveBtn === 1 ?
                                                        <a href="#"
                                                           onClick={() => {
                                                               approveServiceByManager()
                                                           }}
                                                           className="btn btn-warning"
                                                        >
                                                            Согласовать
                                                        </a> : ''
                                                    }
                                                    {role !== 'Partner'
                                                    && showApproveBtn !== 2
                                                        ?
                                                        <a href="#"
                                                           onClick={() => {
                                                               sendOrderToWork()
                                                           }}
                                                           className={`btn btn-success`}
                                                        >
                                                            В работу
                                                        </a> : ''
                                                    }
                                                </div>

                                            </div> : ''
                                        }

                                    </div>
                                </div>

                            </div>
                        </div>
                    )
                }
            }
            if (role === 'Partner') {
                if (showAddServices) {
                    return (
                        <>
                            <div className="tab-content tab-content-bottom " style={{width: '100%'}}>
                                <div id="v-pills-tab10-bottom" className="tab-pane fade show in active">
                                    <div
                                        className="modal-block__footer d-flex justify-content-between align-items-center">
                                        <div>
                                            <div className="total-service">Заказ
                                                на <span>{servicesForFooter.length}</span> услуг
                                            </div>
                                            <p className="text11pxnormalblue d-block total-service-name">
                                                {servicesForFooter.map((item, index) => item.name + " ")}
                                            </p>
                                        </div>
                                        <div className="d-flex gap-2">
                                            {services.currentService.order?.status_code === 'toPartner' && services.currentService.order?.status_code !== 'cancelled' ?
                                                <div style={
                                                    {
                                                        display: 'flex',
                                                        gap: '10px',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                    {services.currentService.order?.status_code !== 'cancelled' ?
                                                        <a href="#"
                                                           style={{maxWidth: '120px'}}
                                                           onClick={() => {
                                                               closeAddService()
                                                           }}
                                                           className="btn-text btn-outline-danger text-center mr-3">
                                                            Отменить
                                                        </a> : ''
                                                    }
                                                    <div className="service_buttons_col">
                                                        <a href="#"
                                                           onClick={() => {
                                                               handleAddServiceToOrder()
                                                           }}
                                                           className={`btn btn-success`}
                                                           style={{minWidth: '200px'}}
                                                        >
                                                            Добавить услуги
                                                        </a>
                                                    </div>
                                                </div> : ''
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>
                    )
                } else {
                    return (
                        <>
                            <div className="tab-content tab-content-bottom " style={{width: '100%'}}>
                                <div id="v-pills-tab10-bottom" className="tab-pane fade show in active">
                                    <div
                                        className="modal-block__footer d-flex justify-content-between align-items-center">
                                        <div>
                                            <div className="total-service">Заказ
                                                № <span>{services.currentService.order?.id}</span> от {formatDate(services.currentService.order?.created_at)}
                                            </div>
                                            <p className="text11pxnormalblue d-block total-service-name">
                                                {services.currentService.order?.status_name}
                                            </p>

                                        </div>
                                        <div className="d-flex gap-2">
                                            {services.currentService.order?.status_code === 'toPartner' && services.currentService.order?.status_code !== 'cancelled' ?
                                                <>
                                                    <a href="#"
                                                       style={{maxWidth: '110px'}}
                                                       onClick={() => {
                                                           cancelServiceById()
                                                       }}
                                                       className="btn-text btn-outline-danger text-center d-flex justify-content-center align-items-center">
                                                        Отменить заказ
                                                    </a>
                                                    <a href="#"
                                                       onClick={() => {
                                                           approveServiceByPartner()
                                                       }}
                                                       className="btn btn-success"
                                                    >
                                                        Согласовать
                                                    </a>

                                                    <button
                                                        onClick={() => {
                                                            openAddService()
                                                        }}
                                                        className={' btn-add__service'}
                                                    >
                                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M12.5 4.5C13.0523 4.5 13.5 4.94772 13.5 5.5V11.5H19.5C20.0523 11.5 20.5 11.9477 20.5 12.5C20.5 13.0523 20.0523 13.5 19.5 13.5H13.5V19.5C13.5 20.0523 13.0523 20.5 12.5 20.5C11.9477 20.5 11.5 20.0523 11.5 19.5V13.5H5.5C4.94772 13.5 4.5 13.0523 4.5 12.5C4.5 11.9477 4.94772 11.5 5.5 11.5H11.5V5.5C11.5 4.94772 11.9477 4.5 12.5 4.5Z"
                                                                fill="white"/>
                                                        </svg>
                                                        Добавить услугу
                                                    </button>
                                                </>
                                                : ''
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>
                    )
                }
            }
            break
        default :
            return (
                <>
                    <div className="tab-content tab-content-bottom " style={{width: '100%'}}>
                        <div id="v-pills-tab10-bottom" className="tab-pane fade show in active">
                            <div className="modal-block__footer d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="total-service">Заказ
                                        на <span>{servicesForFooter.length}</span> услуг
                                    </div>
                                    <p className="text11pxnormalblue d-block total-service-name">
                                        {servicesForFooter.map((item, index) => item.name + " ")}
                                    </p>

                                </div>
                                {/*<div className="d-flex">*/}
                                {/*    <a href="#" onClick={()=>{sendApplicationSelectedServices()}} className="btn btn-success">Сделать заказ</a>*/}
                                {/*</div>*/}
                            </div>

                        </div>
                    </div>
                </>
            )
    }

}

export default ApplicationFooterService;